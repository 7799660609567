import { writable, get } from "svelte/store";
export function create_loading_status_store() {
    const store = writable({});
    const fn_inputs = {};
    const fn_outputs = {};
    const pending_outputs = new Map();
    const pending_inputs = new Map();
    const inputs_to_update = new Map();
    const fn_status = {};
    function update({ fn_index, status, queue = true, size, position = null, eta = null, message = null, progress, time_limit = null }) {
        const outputs = fn_outputs[fn_index];
        const inputs = fn_inputs[fn_index];
        const last_status = fn_status[fn_index];
        const outputs_to_update = outputs.map((id) => {
            let new_status;
            const pending_count = pending_outputs.get(id) || 0;
            // from (pending -> error) | complete - decrement pending count
            if (last_status === "pending" && status !== "pending") {
                let new_count = pending_count - 1;
                pending_outputs.set(id, new_count < 0 ? 0 : new_count);
                new_status = new_count > 0 ? "pending" : status;
                // from pending -> pending - do nothing
            }
            else if (last_status === "pending" && status === "pending") {
                new_status = "pending";
                // (error | complete) -> pending - - increment pending count
            }
            else if (last_status !== "pending" && status === "pending") {
                new_status = "pending";
                pending_outputs.set(id, pending_count + 1);
            }
            else {
                new_status = status;
            }
            return {
                id,
                queue_position: position,
                queue_size: size,
                eta: eta,
                status: new_status,
                message: message,
                progress: progress
            };
        });
        inputs.forEach((id) => {
            const pending_count = pending_inputs.get(id) || 0;
            // from (pending -> error) | complete - decrement pending count
            if (last_status === "pending" && status !== "pending") {
                let new_count = pending_count - 1;
                pending_inputs.set(id, new_count < 0 ? 0 : new_count);
                inputs_to_update.set(id, status);
            }
            else if (last_status !== "pending" && status === "pending") {
                pending_inputs.set(id, pending_count + 1);
                inputs_to_update.set(id, status);
            }
            else {
                inputs_to_update.delete(id);
            }
        });
        store.update((outputs) => {
            outputs_to_update.forEach(({ id, queue_position, queue_size, eta, status, message, progress }) => {
                outputs[id] = {
                    queue: queue,
                    queue_size: queue_size,
                    queue_position: queue_position,
                    eta: eta,
                    message: message,
                    progress,
                    status,
                    fn_index
                };
            });
            return outputs;
        });
        fn_status[fn_index] = status;
    }
    function register(index, inputs, outputs) {
        fn_inputs[index] = inputs;
        fn_outputs[index] = outputs;
    }
    return {
        update,
        register,
        subscribe: store.subscribe,
        get_status_for_fn(i) {
            return fn_status[i];
        },
        get_inputs_to_update() {
            return inputs_to_update;
        }
    };
}
